import {apiCall, paramsToString} from "utils/api";
import {sellerStoreId} from 'utils/auth';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/saas/notifications`;

export const getNotification = async ({ unreadOnly, limit, offset }: { unreadOnly: boolean; limit: number; offset: number }) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/get?${paramsToString({
      unreadOnly: !!unreadOnly,
      limit: limit || 10,
      offset: offset || 0,
    })}`,
  });

  return response.data;
}

export const getUnreadNotificationCount = async () => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/unreadCount`,
  });

  return response.data;
}

export const markNotificationAsRead = async (notificationIds: number[]) => {
  const response = await apiCall({
    method: 'put',
    url: `${HOST}/read`,
    data: notificationIds
  });

  return response.data;
}

export const markAllNotificationAsRead = async () => {
  const response = await apiCall({
    method: 'put',
    url: `${HOST}/${sellerStoreId()}/read`,
  });

  return response.data;
}
