import { toast } from "react-toastify";
import { getUnreadNotificationCount, getNotification } from "data/notification";
import {
  SET_NOTIFICATION_COUNT,
  SET_NOTIFICATION_IS_UNREAD_ONLY,
  UPDATE_NOTIFICATION_DETAILS,
  UPDATE_NOTIFICATION_DATA,
  SET_NOTIFICATION_DATA,
  UPDATE_NOTIFICATION_INFO,
} from "actions/types";
import { NotificationProps } from "types";
import {isUserLoggedIn} from "../utils/auth";

export const updateNotificationDetails =
  (data: {
    notificationCount?: number;
    notificationData?: {
      limit?: number;
      offset?: number;
      notifications?: NotificationProps[];
    };
    isUnreadOnly?: boolean;
  }) =>
  (dispatch: any) => {
    dispatch({ type: UPDATE_NOTIFICATION_DETAILS, payload: data });
  };

export const setNotificationCount = () => async (dispatch: any) => {
  if(isUserLoggedIn()){
    try {
      const res = await getUnreadNotificationCount();

      if (res?.unreadCount || res?.unreadCount === 0)
        dispatch({ type: SET_NOTIFICATION_COUNT, payload: res.unreadCount });
    } catch (err) {
      const res = err.response;
      toast.error(res?.data?.status);
    }
  }
};

export const setNotificationIsUnreadOnlyFlog =
  (isUnreadOnly: boolean) => (dispatch: any) => {
    dispatch({ type: SET_NOTIFICATION_IS_UNREAD_ONLY, payload: isUnreadOnly });
  };

export const updateNotifications =
  (
    params: { unreadOnly: boolean; limit: number; offset: number },
    callback?: (res: { notifications:  NotificationProps[] } | undefined) => void
  ) =>
  async (dispatch: any) => {
    try {
      const res = await getNotification(params);

      if (res) {
        dispatch({
          type: UPDATE_NOTIFICATION_DATA,
          payload: {
            limit: params.limit,
            offset: params.offset,
            notifications: res,
          },
        });
      }

      callback && callback(res);
    } catch (err) {
      const res = err.response;
      toast.error(res?.data?.status);
    }
  };

export const setNotifications =
  (
    params: { unreadOnly: boolean; limit: number; offset: number }, 
    callback?: (res: { notifications:  NotificationProps[] } | undefined) => void
  ) =>
  async (dispatch: any) => {
    try {
      const res = await getNotification(params);

      if (res) {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: {
            limit: params.limit,
            offset: params.offset,
            notifications: res,
          },
        });
      }

      callback && callback(res);
    } catch (err) {
      const res = err.response;
      toast.error(res?.data?.status);
    }
  };

export const updateNotificationInfo =
  (notification: NotificationProps) => (dispatch: any) => {
    dispatch({ type: UPDATE_NOTIFICATION_INFO, payload: notification });
  };
