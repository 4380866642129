import dynamic from 'next/dynamic';
import Image from "next/image";
import cloutLogo from 'assets/clout_logo.svg';
import {useRouter} from "next/router";
import {CountryPreference, ReduxState} from "types";
import {useDispatch, useSelector} from "react-redux";
import { useEffect, useState } from 'react';
import { setNotificationCount } from 'actions/headerNotificationActions';
import { getWhatsappSubscriptionData } from 'actions/usersAction';
import { checkCountryPreference } from 'utils/misc';
import { COUNTRY_CODE } from 'constants/store';
import useBreakpoint from 'hooks/useBreakpoint';
import { Screen } from 'constants/breakpoints';
import wishlistDarkIcon from 'assets/heart-dark.svg';
import Link from 'next/link';
import rnaAnalytics from 'services/analytics/rnaAnalytics';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsPageSectionName } from 'constants/analytics';
import { isInvestorStore } from 'utils/auth';

const Freshchat = dynamic(() => import('components/Freshchat/_default/index'));
const ProfileDropdown = dynamic(() => import('components/Header/ProfileDropdown'));
const SearchWidget = dynamic(() => import("components/SearchWidget/_default/SearchWidget"));
const SelectCountry = dynamic(() => import("components/Header/SelectCountry"));
const HeaderNotification = dynamic(() => import("components/HeaderNotification"));
const NotificationDrawer = dynamic(() => import("components/HeaderNotification/NotificationDrawer"));
const Tooltip = dynamic(() => import("components/Tooltip/_default/Tooltip"));
const SearchWidgetMobile = dynamic(() => import("components/SearchWidgetMobile"));

const Header = ({ userCountryPreference }: { userCountryPreference: CountryPreference }) => {
  const isSmScreen = useBreakpoint(Screen.SmScreen);

  const user = useSelector((state: ReduxState) => state?.user);
  const notificationCount = useSelector((state: any) => state?.headerNotification?.notificationCount || 0);

  const router = useRouter();
  const dispatch: any = useDispatch();

  const [showNotificationDrawer, setNotificationDrawer] = useState(false);

  // Redirection should ideally be here, but not changing this right now

  // useEffect(() => {
  //   if (user?.storesFetched) {
  //     if (!user?.shopifyStoreData?.shopifyStores?.length) {
  //       router.replace({
  //         pathname: '/link-shopify',
  //         query: getState(router),
  //       });
  //       return;
  //     }
  //   }
  // }, [user]);

  useEffect(() => {
    dispatch(getWhatsappSubscriptionData());
  }, []);

  const handleRoposoLogoClick = () => {
    router.push('/');
  }

  const handleNotificationClick = () => {
    setNotificationDrawer(!showNotificationDrawer);
    dispatch(setNotificationCount());

    rnaAnalytics.clickEvent({
      action: AnalyticsEventAction.NotificationIconClicked,
      eventData: {
        [AnalyticsEvent.Extras]: {
          [AnalyticsEvent.PageSectionName]: AnalyticsPageSectionName.TopNavbar,
          [AnalyticsEvent.NotificationCount]: notificationCount,
        }
      }
    }).send();
  };

  const handleCloseNotificationDrawer = () => {
    setNotificationDrawer(false);
  }

  const handleLogoClick = () => {
    rnaAnalytics.clickEvent({
      action: AnalyticsEventAction.RoposoCloutLogoClicked,
      eventData: {
        [AnalyticsEvent.Extras]: {
          [AnalyticsEvent.PageSectionName]: AnalyticsPageSectionName.TopNavbar
        }
      }
    }).send();
  }

  const handleWishlistClick = () => {
    rnaAnalytics.clickEvent({
      action: AnalyticsEventAction.WishlistIconClicked,
      eventData: {
        [AnalyticsEvent.Extras]: {
          [AnalyticsEvent.PageSectionName]: AnalyticsPageSectionName.TopNavbar
        }
      }
    }).send();
  }

  return (
    <>
      <Freshchat user={user} />
      <div
        className="bg-theme_1_white fixed left-0 top-0 w-screen z-[130] shadow-[0_4px_10px_0px_rgba(0,0,0,0.1)]">
        <div className='flex justify-between px-[30px] items-center h-[60px] smScreen:h-[48px] smScreen:px-[16px]'>
          <div 
            className='flex items-center'
            onClick={handleLogoClick}
          >
            <div className='w-[90px] h-[48px] smScreen:w-[70px] smScreen:h-[38px] flex items-center'>
              <Image src={cloutLogo}
                    alt={'clout-logo'}
                    onClick={handleRoposoLogoClick}
                    className='cursor-pointer'
                    id="app-logo"
                    loading="eager"
                    objectFit='contain'
              />
              <div className='h-[36px] smScreen:h-[26px] mx-2 border-l border-[#C2C2C2]'></div>
            </div>

            {user?.storesFetched && (
              <div className='text-md text-[#424242]'>
                {user?.shopifyStoreData?.isB2BStore ? "For Business" : "Marketplace"}
              </div>
            )}
          </div>
          <div className={'w-[550px] smScreen:hidden'}>
            {!isInvestorStore() && (
              <SearchWidget
                userCountryPreference={userCountryPreference}
              />
            )}
          </div>
          <div className={'flex justify-between text-[16px] items-center'}>
            <SelectCountry countryData={user?.shopifyStoreData?.countriesData} />

            {!isInvestorStore() && isSmScreen && (
              <SearchWidgetMobile
                userCountryPreference={userCountryPreference}
              />
            )}

            {/* Wishlist */}
            {!isInvestorStore() && !checkCountryPreference(userCountryPreference, COUNTRY_CODE.us) && isSmScreen && (
              <div 
                className='w-[24px] h-[24px] ml-4'
                onClick={handleWishlistClick}
              >
                <Link 
                  href={"/wishlist"}
                  passHref
                >
                  <a>
                    <Image
                      src={wishlistDarkIcon}
                      width={24}
                      height={24}
                    />
                  </a>
                </Link>
              </div>
            )}

            {!isInvestorStore() && !checkCountryPreference(userCountryPreference, COUNTRY_CODE.us) && (
              <Tooltip
                message={<div className='text-[10px]'>{`Notification${notificationCount > 1 ? 's': ''}`}</div>}
                textWrapperClass="!left-[12px]"
                wrapperClass='inline-block w-[16px] h-[16px] min-w-[27px] ml-5 smScreen:ml-4'
                position='bottom'
                customComponent={(
                  <div className='mt-[-3px]'>
                    <HeaderNotification
                      onNotificationClick={handleNotificationClick}
                    />
                  </div>
                )}
              />
            )}

          {/* Notification Drawer */}
          {showNotificationDrawer && (
            <>
              <div 
                className='absolute top-0 left-0 right-0 h-[100vh]' 
                onClick={handleCloseNotificationDrawer}>
              </div>
              <div className='absolute right-[30px] top-[52px] z-[220] smScreen:right-[16px] smScreen:top-[45px] smScreen:scale-[0.7] smScreen:origin-top-right'>
                <NotificationDrawer />
              </div>
            </>
          )}

          {/* Avatar */}
          <ProfileDropdown
            user={user}
          />
          </div>
        </div>
      </div>
    </>
  );
}

// @ts-ignore
export default Header;
